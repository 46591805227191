import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { createId, decryptUniqueFilename } from "../../utils/utilities";
import Summernote from '../utils/Summernote';
import { T } from "../../utils/i18n-config";
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { InternalControlComponentsQuestions } from "../../models/special-document/ElementArgs"
import { Permissions } from '../../models/special-document/ElementBase';
import InternalControlComponentsQuestionsOptionEdit from './InternalControlComponentsQuestionsOptionEdit'
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeBase from "./EditModeBase"
import EditModeProps from './EditModeProps'

export interface InternalControlComponentsConclusionData {
  id: number;
  name: string;
  min_scale: number;
  max_scale: number;
  error?: string;
}

export interface InternalControlComponentsConclusion {
  success: boolean,
  data_conclusion: InternalControlComponentsConclusionData[],
}

const InternalControlComponentsConclusionEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current;
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [ranges, setRanges] = useState<InternalControlComponentsConclusionData[]>([]);
  const [rangesBase , setRangesBase] = useState<InternalControlComponentsConclusionData[]>([]);
  
  const getResultOfAnswers = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getConclusionScaleInternalControlComponents(element.engagement_id, element.documentId, element.id)
    if (result.success) {
      setRanges(result.data_conclusion)
      setRangesBase(result.data_conclusion)
    } else {
      setRanges([])
      setRangesBase([])
    }
  }

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  useEffect(() => {
    getResultOfAnswers()
  }, []); 

  const validateRanges = (updatedRanges: InternalControlComponentsConclusionData[]) => {
    const validatedRanges = updatedRanges.map((range, index) => {
      let error = "";

      if (range.max_scale <= range.min_scale) {
        error = `The upper limit (${range.max_scale}) must be greater than the lower limit (${range.min_scale}).`;
      }

      if (
        index < updatedRanges.length - 1 &&
        range.max_scale >= updatedRanges[index + 1].min_scale
      ) {
        error = `The upper limit of ${range.name} (${range.max_scale}) overlaps with the lower limit of ${
          updatedRanges[index + 1].name
        } (${updatedRanges[index + 1].min_scale}).`;
      }

      return { ...range, error };
    });

    setRanges(validatedRanges);
  };

  const handleRangeChange = (
    index: number,
    field: "min_scale" | "max_scale",
    value: number
  ) => {
    const updatedRanges = [...ranges];
    updatedRanges[index] = { ...updatedRanges[index], [field]: value };

    setRanges(updatedRanges);
    validateRanges(updatedRanges); // Valida los rangos al cambiar
  };


  const handleConfigurationsModalAccept = async () => {
    const errors = ranges
    .filter((range) => range.error)
    .map((range) => `${range.name}: ${range.error}`);
    if (errors.length == 0) {
      setShowConfigurationsModal(false);
      const elementRepository = new ElementRepository()
      const result = await elementRepository.updateConclusionScaleControlComponents(element.engagement_id, element.documentId, element.id, ranges)
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setRanges(rangesBase)
  };

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="card h-100 bg-light">
          <div className="card-body p-3">
            <h6>{T("Element for displaying the internal control components conclusion")}</h6>
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons 
        showModal={showConfigurationsModal}
        title={T("Conclusion parameters")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>

      {ranges.map((range, index) => (
            <div key={index} style={{ marginBottom: "1rem" }}>
              <h4>{range.name}</h4>
              <label>
                Lower Limit:
                <input
                  type="number"
                  value={range.min_scale}
                  className="form-control"
                  onChange={(e) =>
                    handleRangeChange(index, "min_scale", parseFloat(e.target.value))
                  }
                />
              </label>
              <label>
                Upper Limit:
                <input
                  type="number"
                  value={range.max_scale}
                  className="form-control"
                  onChange={(e) =>
                    handleRangeChange(index, "max_scale", parseFloat(e.target.value))
                  }
                />
              </label>
              {range.error && <p style={{ color: "red", marginTop: "0.5rem" }}>{range.error}</p>}
            </div>
          ))}
          </div>
      </ModalWithButtons>
    </div>
  );
};

export default InternalControlComponentsConclusionEditMode;
