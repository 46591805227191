import React, { useState, useId } from 'react';
import { T } from "../../utils/i18n-config"
import { decryptUniqueFilename } from '../../utils/utilities';
import Summernote from '../utils/Summernote';
import Dropzone from '../utils/Dropzone';


interface InternalControlComponentsQuestionsOptionProps {
  index: number;
  elementId: number;
  question: string;
  isParentQuestion: boolean;
  hasDropdown: boolean;
  hasYesNoInverse: boolean;
  attachmentName: string;
  showModal: boolean;
  handleEditQuestion: (index: number, value: string) => void;
  handleSetTypeQuestion: (index: number, isParentQuestion: boolean) => void;
  handleSetHasDropdown: (index: number, hasDropdown: boolean) => void;
  handleSetHasYesNoInverse: (index: number, hasYesNoInverse: boolean) => void;
  handleEditAttachmentName: (index: number, attachment_name: string) => void;
}


const InternalControlComponentsQuestionsOptionEdit = ({
  index, elementId, question, isParentQuestion, hasDropdown, hasYesNoInverse, attachmentName, showModal,
  handleEditQuestion, handleSetTypeQuestion, handleSetHasDropdown, handleSetHasYesNoInverse, handleEditAttachmentName
}: InternalControlComponentsQuestionsOptionProps) => {
  const [switchTypeQuestion, setSwitchTypeQuestion] = useState(isParentQuestion);
  const [switchHasDropdown, setSwitchHasDropdown] = useState(hasDropdown);
  const [switchYesNoInverse, setSwitchYesNoInverse] = useState(hasYesNoInverse);

  const [attachment, setAttachment] = useState(attachmentName);
  const switchId = useId();

  function handleTypeQuestion(isParentQuestion: boolean) {
    setSwitchTypeQuestion(isParentQuestion);
    handleSetTypeQuestion(index, isParentQuestion);
  }

  function handleHasDropdown(hasDropdown: boolean) {
    setSwitchHasDropdown(hasDropdown);
    handleSetHasDropdown(index, hasDropdown);
  }

  function handleHasYesNoInverse(hasYesNoInverse: boolean) {
    setSwitchYesNoInverse(hasYesNoInverse);
    handleSetHasYesNoInverse(index, hasYesNoInverse);
  }

  function handleSetAttachment(attachmentName: string, originalFilename: string) {
    setAttachment(originalFilename);
    handleEditAttachmentName(index, attachmentName);
  }

  return (
    <div>
      <div className="form-group">
        <h5 className="mb-0">{T("Question")}</h5>
        <Summernote
              value={question}
              onChange={(e) => handleEditQuestion(index, e)}
              showModal={showModal}
            />
        <input type="text" className="form-control" placeholder={T('Enter question')} value={question} onChange={(e) => handleEditQuestion(index, e.target.value)} />
      </div>
      <div className="form-group">        
        <div className="d-flex justify-content-end gap-2">
        <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Normal question")}</label>
            <input
              type="checkbox"
              role="switch"
              id={switchId}
              className="form-check-input m-0"
              checked={switchTypeQuestion}
              onChange={() => handleTypeQuestion(!switchTypeQuestion)}
            />
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Parent question")}</label>
          </div>
          <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Dropdown")}</label>
            <input
              type="checkbox"
              role="switch"
              id={switchId}
              className="form-check-input m-0"
              checked={switchHasDropdown}
              onChange={() => handleHasDropdown(!switchHasDropdown)}
            />
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Yes/No")}</label>
          </div>
          {hasDropdown == true && (
          <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Normal Yes/No")}</label>
            <input
              type="checkbox"
              role="switch"
              id={switchId}
              className="form-check-input m-0"
              checked={switchYesNoInverse}
              onChange={() => handleHasYesNoInverse(!switchYesNoInverse)}
            />
            <label className="form-check-label m-0" htmlFor={switchId}>{T("Inverse Yes/No")}</label>
          </div>
          )}
        </div>
      </div>
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default InternalControlComponentsQuestionsOptionEdit
