import React, { useEffect, useRef, useState } from 'react'
import { T } from "../../utils/i18n-config"
import { InternalControlComponentsQuestionsElement } from "../../models/special-document/InternalControlComponentsQuestionsElement"
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { Permissions } from '../../models/special-document/ElementBase'
import ViewModeProps from './ViewModeProps';
import ViewModeBase from "./ViewModeBase";


const InternalControlComponentsConclusionViewMode: React.FC<ViewModeProps> = ({ iElement }: ViewModeProps) => {
  const element = useRef<InternalControlComponentsQuestionsElement>(iElement as InternalControlComponentsQuestionsElement).current;
  const [valoration, setValoration] = useState<string | undefined| null>(undefined)

  const getResultOfAnswers = async () => {
    const elementRepository = new ElementRepository()
    const result = await elementRepository.getResultOfAnswersInternalControlComponents(element.engagement_id, element.documentId, element.id)
   
    if (result.success) {
      if (result.valoration == 1) {
        setValoration(T("Initial"))
      } else if (result.valoration == 2) {
        setValoration(T("Basic"))
      } else if (result.valoration == 3) {
        setValoration(T("Intermediate"))
      } else if (result.valoration == 4) {
        setValoration(T("Optimal"))
      } else {
        setValoration(T("Out of range"))
      }
    } else {
      setValoration(undefined)
    }
  }

  useEffect(() => {
    getResultOfAnswers()
  }, []); 

  return (
    <div>
      <ViewModeBase
        isEditable={false}
        permissions={element.permissions as Permissions}>
        {valoration !== undefined && valoration !== null && (
          <div className="card h-100 d-flex flex-column justify-content-center align-items-center">
              <h6>{T("Internal control systems")}</h6>
              <div className="chart pt-3">
                <p>{T(valoration || "")}</p>
              </div>
          </div>
          )
        }
      </ViewModeBase>
    </div>
  );
};

export default InternalControlComponentsConclusionViewMode;
