import React, { useEffect, useRef, useState, useId } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { createId, decryptUniqueFilename } from "../../utils/utilities";
import Summernote from '../utils/Summernote';
import { T } from "../../utils/i18n-config";
import { ElementRepository } from "../../repository/special-document/ElementRepository"
import { InternalControlComponentsConfigurationElement } from "../../models/special-document/InternalControlComponentsConfigurationElement"
import { InternalControlComponentsQuestions } from "../../models/special-document/ElementArgs"
import { Permissions } from '../../models/special-document/ElementBase';
import ModalWithButtons from '../modals/AceptCancelModalBase'
import EditModeBase from "./EditModeBase"
import EditModeProps from './EditModeProps'

export interface InternalControlComponentsData {
  id: number | string;
  name: string;
  configuration_value?: number;
  is_question_finding?: boolean;
  is_required_file?: boolean;
  changed?: boolean;
}

export interface InternalControlComponentsConfigurations {
  success: boolean,
  data_dropdown: InternalControlComponentsData[],
  data_yes_no: InternalControlComponentsData[],
  data_yes_no_inverse: InternalControlComponentsData[]
}

const InternalControlComponentsConfigurationEditMode: React.FC<EditModeProps> = ({ iElement, handleDeleteElement, handleUpElement, handleDownElement }: EditModeProps) => {
  const element = useRef<InternalControlComponentsConfigurationElement>(iElement as InternalControlComponentsConfigurationElement).current
  const [showConfigurationsModal, setShowConfigurationsModal] = useState(false)
  const [dataDropdown, setDataDropdown] = useState<InternalControlComponentsData[]>([])
  const [dataYesNo, setDataYesNo] = useState<InternalControlComponentsData[]>([])
  const [dataYesNoInverse, setDataYesNoInverse] = useState<InternalControlComponentsData[]>([])
  const [refresh, setRefresh] = useState(false)

  const getInternalControlComponentsConfigurations = async () => {
    const elementRepository = new ElementRepository()
    const data = await elementRepository.getInternalControlComponentsConfiguration(
      element.engagement_id, element.documentId, element.id, "edition"
    )
    if (data.success) {
      setDataDropdown(data.data_dropdown)
      setDataYesNo(data.data_yes_no)
      setDataYesNoInverse(data.data_yes_no_inverse)
    } else {
      setDataDropdown([])
      setDataYesNo([])
      setDataYesNoInverse([])
    }
  }

  useEffect(() => {
    getInternalControlComponentsConfigurations()
  }, [refresh]);

  const handleConfiguration = () => {
    setShowConfigurationsModal(true);
  };

  const handleConfigurationsModalAccept = async () => {
    const elementsRepository = new ElementRepository()
    const success = await elementsRepository.updateInternalControlComponentsConfiguration(
      element.engagement_id, element.documentId, element.id, columnsData
    )
    setShowConfigurationsModal(false);
    if (success) {
      setRefresh(!refresh)
    }
  };

  const handleConfigurationsModalCancel = () => {
    setShowConfigurationsModal(false)
    setColumnsData([
      {
        type: "dropdown_answer",
        items: dataDropdown.map((item) => ({ ...item, changed: false })),
      },
      {
        type: "yes_no_answer",
        items: dataYesNo.map((item) => ({ ...item, changed: false })),
      },
      {
        type: "yes_no_answer_inverse",
        items: dataYesNoInverse.map((item) => ({ ...item, changed: false })),
      },
    ]);

  };
  //   const elementRepository = new ElementRepository()
  //   const lastQuestion = element.title_section
  //   const lastParagraph = element.paragraph_section
  //   const lastOptions = JSON.parse(JSON.stringify(element.questions_internal_control))
  //   element.title_section = title
  //   element.questions_internal_control = JSON.parse(JSON.stringify(options))

  //   let success = await elementRepository.saveElement("edition", element.args)
  //   //let success = false
  //   if (!success) {
  //     element.title_section = lastQuestion
  //     element.questions_internal_control = lastOptions
  //     setTitle(lastQuestion)
  //     setOptions(JSON.parse(JSON.stringify(lastOptions)))
  //     window.htmlHelpers?.swalError()
  //   }
  // };

  const [columnsData, setColumnsData] = useState<
    { type: string; items: InternalControlComponentsData[] }[]
  >([]);

  const switchId = useId();

  useEffect(() => {
    setColumnsData([
      {
        type: "dropdown_answer",
        items: dataDropdown.map((item) => ({ ...item, changed: false })),
      },
      {
        type: "yes_no_answer",
        items: dataYesNo.map((item) => ({ ...item, changed: false })),
      },
      {
        type: "yes_no_answer_inverse",
        items: dataYesNoInverse.map((item) => ({ ...item, changed: false })),
      },
    ]);
  }, [dataDropdown, dataYesNo, dataYesNoInverse]);

  const handleDelete = (columnIndex: number, itemId: number | string) => {
    const updatedColumns = [...columnsData];
    const regex = /^id-\d+$/;
    const itemToDelete = updatedColumns[columnIndex].items.find((item) => item.id === itemId);

    if (!itemToDelete) {
      return;
    }

    if (!regex.test(String(itemId))) {
      const elementRepository = new ElementRepository();
      elementRepository.deleteInternalControlComponentsConfiguration(
        element.engagement_id,
        element.documentId,
        itemToDelete.id,
      );
    }

    updatedColumns[columnIndex].items = updatedColumns[columnIndex].items.filter(
      (item) => item.id !== itemId
    );
    setColumnsData(updatedColumns);
  };

  const handleAdd = (columnIndex: number) => {
    const newEntry: InternalControlComponentsData = {
      id: `id-${Date.now()}`,
      name: '',
      configuration_value: 0,
      changed: true,
      is_question_finding: false,
      is_required_file: false,
    };

    const updatedColumns = [...columnsData];
    updatedColumns[columnIndex].items.push(newEntry);
    setColumnsData(updatedColumns);
  };

  const handleChange = (
    columnIndex: number,
    itemId: number | string,
    field: keyof InternalControlComponentsData,
    value: any
  ) => {
    const updatedColumns = [...columnsData];
    updatedColumns[columnIndex].items = updatedColumns[columnIndex].items.map((item) =>
      item.id === itemId
        ? {
          ...item,
          [field]:
            field === "configuration_value"
              ? value === "" || isNaN(Number(value))
                ? value
                : Number(value)
              : value,
          changed: true,
        }
        : item
    );
    setColumnsData(updatedColumns);
  };

  const handleSetHasFinding = (columnIndex: number,
    itemId: number | string,
    field: keyof InternalControlComponentsData,
    value: any) => {
    const updatedColumns = [...columnsData];
    updatedColumns[columnIndex].items = updatedColumns[columnIndex].items.map((item) =>
      item.id === itemId
        ? {
          ...item,
          [field]:
            field === "configuration_value"
              ? value === "" || isNaN(Number(value))
                ? value
                : Number(value)
              : value,
          changed: true,
        }
        : item
    );
    setColumnsData(updatedColumns);
  }

  const handleSetHasRequiredFile = (columnIndex: number,
    itemId: number | string,
    field: keyof InternalControlComponentsData,
    value: any) => {
    const updatedColumns = [...columnsData];
    updatedColumns[columnIndex].items = updatedColumns[columnIndex].items.map((item) =>
      item.id === itemId
        ? {
          ...item,
          [field]:
            field === "configuration_value"
              ? value === "" || isNaN(Number(value))
                ? value
                : Number(value)
              : value,
          changed: true,
        }
        : item
    );
    setColumnsData(updatedColumns);
  }

  const titleMap: { [key: string]: string } = {
    dropdown_answer: "Dropdown answers",
    yes_no_answer: "Yes/No answers",
    yes_no_answer_inverse: "yes/No answers inverses",
  };
  

  return (
    <div>
      <EditModeBase
        iElement={iElement}
        isEditable={false}
        handleDeleteElement={handleDeleteElement}
        handleUpElement={handleUpElement}
        handleDownElement={handleDownElement}
        handleConfiguration={handleConfiguration}
        permissions={iElement.permissions as Permissions}>
        <div className="container p-0">
          <h4>{T("Internal control components configuration")}</h4>
          <div className="row">
            {columnsData.map((column, columnIndex) => (
              <div className="col-md-4" key={columnIndex}>
                <h5>{T(titleMap[column.type])}</h5>
                <ul className="list-group">
                  {column.items.map((item) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center px-2"
                      key={item.id}
                    >
                      <div className="d-flex justify-content-between w-100">
                        <span className="mb-2 font-weight-bold">{item.name}</span>
                        <span>{item.configuration_value === 0 || item.configuration_value === null ? "0" : item.configuration_value}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </EditModeBase>
      <ModalWithButtons
        showModal={showConfigurationsModal}
        title={T("Answer configuration")}
        size="xl"
        onAccept={handleConfigurationsModalAccept}
        onCancel={handleConfigurationsModalCancel}>
        <div className="container p-0">
          <div className="row">
            {columnsData.map((column, columnIndex) => (
              <div className="col-md-4" key={columnIndex}>
                <h5>{T(titleMap[column.type])}</h5>
                <ul className="list-group">
                  {column.items.map((item) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center px-2"
                      key={item.id}
                    >
                      <div className="d-flex flex-column w-90">
                        <input
                          type="text"
                          className="form-control mb-2 d-block"
                          value={item.name}
                          onChange={(e) =>
                            handleChange(columnIndex, item.id, "name", e.target.value)
                          }
                          placeholder="Name"
                        />
                        <input
                          type="text"
                          className="form-control d-block"
                          value={
                            item.configuration_value === 0 || item.configuration_value === null
                              ? "0"
                              : item.configuration_value
                          }
                          onChange={(e) =>
                            handleChange(
                              columnIndex,
                              item.id,
                              "configuration_value",
                              e.target.value
                            )
                          }
                          placeholder="Value"
                        />
                        <div className="d-flex flex-column align-items-center gap-2 mt-1">
                        <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
                            <label style={{ fontSize: "12px" }} className="form-check-label m-0" htmlFor={switchId}>{"No Genera hallazgos"}</label>
                            <input
                              type="checkbox"
                              role="switch"
                              id={switchId}
                              className="form-check-input m-0"
                              checked={item.is_question_finding}
                              onChange={() => handleSetHasFinding(columnIndex, item.id, "is_question_finding", !item.is_question_finding)}
                            />
                            <label style={{ fontSize: "12px" }} className="form-check-label m-0" htmlFor={switchId}>{"Genera hallazgos"}</label>
                          </div>
                          <div className="form-check form-switch d-flex align-items-center gap-2 mb-2">
                            <label style={{ fontSize: "12px" }} className="form-check-label m-0" htmlFor={switchId}>{"No requiere archivo"}</label>
                            <input
                              type="checkbox"
                              role="switch"
                              id={switchId}
                              className="form-check-input m-0"
                              checked={item.is_required_file}
                              onChange={() => handleSetHasRequiredFile(columnIndex, item.id, "is_required_file", !item.is_required_file)}
                            />
                            <label style={{ fontSize: "12px" }} className="form-check-label m-0" htmlFor={switchId}>{"Requiere archivo"}</label>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn btn-danger p-1 m-0"
                        style={{ width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}
                        onClick={() => handleDelete(columnIndex, item.id)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </li>
                  ))}
                </ul>
                <div className="d-flex justify-content-center w-100">
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => handleAdd(columnIndex)}
                >
                  {T("Add Item")}
                </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalWithButtons>
    </div>
  );
};

export default InternalControlComponentsConfigurationEditMode;
