import React, { useState, useId } from 'react';
import { T } from "../../utils/i18n-config"
import DOMPurify from 'isomorphic-dompurify';
import { decryptUniqueFilename } from '../../utils/utilities';
import Summernote from '../utils/Summernote';
import Dropzone from '../utils/Dropzone';
import { InternalControlComponentsData } from './InternalControlComponentsQuestionsEditMode';


interface InternalControlComponentsQuestionsOptionProps {
  index: number;
  elementId: number;
  question: string;
  dataAnswers: InternalControlComponentsData[];
  selectAnswerSelectedId: number | undefined;
  isParentQuestion: boolean;
  notHasDropdown: boolean;
  hasYesNoInverse: boolean;
  justification: string | undefined;
  attachmentName: string;
  showModal: boolean;
  handleAnswerSelected: (index: number, value: number) => void;
  handleEditJustification: (index: number, justification: string) => void;
  handleEditAttachmentName: (index: number, attachment_name: string) => void;
}


const InternalControlComponentsQuestionsOptionView = ({
  index, elementId, question, dataAnswers, selectAnswerSelectedId, justification, isParentQuestion, notHasDropdown, hasYesNoInverse, attachmentName, showModal,
  handleAnswerSelected, handleEditJustification, handleEditAttachmentName
}: InternalControlComponentsQuestionsOptionProps) => {

  const [attachment, setAttachment] = useState(attachmentName);

  const handleEditorJustification = (content: string) => {
    handleEditJustification(index, content);
  };


  function handleSetAttachment(attachmentName: string, originalFilename: string) {
    setAttachment(originalFilename);
    handleEditAttachmentName(index, attachmentName);
  }

  return (
    <div>
      <div className="form-group">
        <h6 className="mb-0">{T("Question")}</h6>
        <div className="mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question ?? "")}} />
      </div>

      <div className="form-group">
        <label>{T("Select Answer")}</label>
        <select className="form-select" value={selectAnswerSelectedId} onChange={(e) => handleAnswerSelected(index, Number(e.target.value))}>
          <option value="">{T("Select an option")}</option>
          {dataAnswers.map((type: InternalControlComponentsData) => {
            return (
              <option key={type.id} value={type.id}>{type.name}</option>
            )
          })}
        </select>
      </div>
      <label className="mb-0">{T("Justification")}</label>
      <Summernote value={justification ?? ""} onChange={handleEditorJustification} showModal={showModal} />
      {
        attachment && (
          <div className="d-flex align-items-center gap-2 py-1 px-2 border border-2 border-secondary rounded" style={{ width: 'fit-content' }}>
            <span className="text-sm fw-bold text-truncate text-uppercase" style={{ maxWidth: '150px' }}>
              {decryptUniqueFilename(attachment)}
            </span>
            <button
              type="button"
              style={{ cursor: 'pointer' }}
              className="p-1 bg-transparent border-0 text-secondary"
              onClick={() => handleSetAttachment('', '')}
            >
              <i className="fa-regular fa-trash-can"></i>
              <span className="visually-hidden">{T("Remove file")}</span>
            </button>
          </div>
        )
      }
      {
        (!attachment) && (
          <Dropzone elementId={elementId} onUpload={handleSetAttachment} />
        )
      }
      <hr className="horizontal dark my-3"></hr>
    </div>
  );
};

export default InternalControlComponentsQuestionsOptionView
